<template>
	<div class="bg-shop-summary">
    <el-card shadow="hover" class="x-card-title-slot mb-3" :body-style="{padding: 0}">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col header-title">
          营收数据
        </div>
        <div class="col-auto">起止日期：</div>
        <div class="col-auto mr-2">
          <el-date-picker
            size="small"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            style="width: 220px;"
            v-model="daterangeDate"
            type="daterange"
            @change="daterangeDateChange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
        <div class="col-auto">
          <el-button type="primary" size="small"  :loading="importLoading" @click="GET_PriceStatistics">搜索</el-button>
        </div>
      </div>

      <div id="priceList" :style="{ height: '50vh' }"></div>
    </el-card>

    <el-card shadow="hover" class="x-card-title-slot mb-3" :body-style="{padding: 0}">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col header-title">
          每日营收统计
        </div>
        <div class="col-auto mr-2">
          <el-date-picker
            v-model="value2"
            size="small"
            value-format="yyyy-MM"
            type="month"
            @change="GET_MontPrice"
            placeholder="请选择月份">
          </el-date-picker>
        </div>
      </div>

      <en-table-layout
        :toolbar="false"
        :pagination="false"
        :tableData="pageData.data"
        :loading="loading">
        <template slot="table-columns">
          <el-table-column label="日期">
            <template slot-scope="scope">{{ scope.row.time  }}</template>
          </el-table-column>
          <el-table-column label="利润">
            <template slot-scope="scope">￥{{ scope.row.profitTurnoverPrice }}</template>
          </el-table-column>
          <el-table-column label="营收总额">
            <template slot-scope="scope">￥{{ scope.row.turnoverPrice }}</template>
          </el-table-column>
          <el-table-column label="下单数">
            <template slot-scope="scope">{{ scope.row.orderTotalNum }}</template>
          </el-table-column>
          <el-table-column label="支付订单数">
            <template slot-scope="scope">{{ scope.row.orderPayNum }}</template>
          </el-table-column>
        </template>
      </en-table-layout>
    </el-card>
	</div>
</template>

<script>
	import * as API_Statistics from '@/api/statistics'
	import * as API_account from '@/api/account'
	import { CategoryPicker } from '@/components'
  import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
	export default {
		name: 'buyAnalysis',
		components: {
      EnTableLayout,
			[CategoryPicker.name]: CategoryPicker
		},
		data() {
			return {
				importLoading:false,
				type: 1,
				typeList: [
					{ label: '新增', value: 1 },
					{ label: '累计', value: 4 },
				],
				pageData: {data: []},
				pickerOptions: {
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '近7日',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '近15日',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '近30日',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				/** 列表loading状态 */
				loading: false,

				daterangeDate: [],

				/** 参数*/
				params: {
					/** 开始时间*/
					start_time: '',

					/** 结束时间 */
					end_time: '',

				},
				/** 参数*/
				params2: {
					/** 当前选择的日期类型 */
					cycle_type: 'MONTH',

					/** 年份 */
					year: '2018',

					/** 月份*/
					month: '6'
				},
				value2:"",
				tableHeight: document.body.clientHeight / 2
			}
		},
		created() {
			//获取当前时间
			var now   = new Date();
			this.daterangeDate[0]=Date.parse(now);
			this.daterangeDate[1]=Date.parse(now);
			this.params.start_time=Date.parse(now)/1000;
			this.params.end_time=Date.parse(now)/1000;

			var monthn = now.getMonth()+1;
			var yearn  = now.getFullYear();

			this.value2 = yearn+"-"+monthn;
			this.GET_PriceStatistics()
			this.GET_MontPrice()
		},
		mounted() {
			window.onresize = this.countTableHeight
			this.$nextTick(() => {
				this.priceListChart = this.$echarts.init(document.getElementById('priceList'))
				// this.purchaseTimeChart = this.$echarts.init(document.getElementById('purchaseTime'))
			})
		},
		methods: {
			downZd(){
				// if(!row){
				// 	this.importLoading = true
				// }
				API_account.getSettleOrderList({}).then(response => {
					this.importLoading = true
					let tHeaders = [nameLabel, '订单号', '买家会员号', '订单金额', '快递单号', '快递名称', '订单日期']
					let filterVals = [nameValue, 'sn', 'member_name', priceValue, 'ship_no', 'logi_name', 'complete_time']
					handleDownload(response, tHeaders, filterVals, '财务月汇总账单')
					this.importLoading = false
				})
			},
			daterangeDateChange(){
				console.log(this.daterangeDate[0])
				this.params.start_time = this.daterangeDate[0] / 1000
				this.params.end_time =this.daterangeDate[1] / 1000
				console.log(this.params.start_time)
			},
			/** 窗口缩放时计算table高度 */
			countTableHeight() {
				this.tableHeight = document.body.clientHeight / 2
				/** 图表刷新 */
				setTimeout(this.priceListChart.resize)
				// setTimeout(this.purchaseTimeChart.resize)
			},

			/** 改变日期的回调*/
			changeYearMonth(obj) {
				this.params = {
					...this.params,

					cycle_type: obj.type,

					year: obj.year
				}
				if (obj.type === 'MONTH') {
					this.params = {
						...this.params,

						cycle_type: obj.type,

						year: obj.year,

						month: parseInt(obj.month)
					}
				}
			},

			/** 价格区间 */
			changePriceRange(obj) {
				this.params = {
					...this.params,
					ranges: obj
				}
			},

			/** 执行搜索 */
			handleSearch() {
				/** 处理区域数据 */
				let ranges = []
				if (this.params.ranges && this.params.ranges.length) {
					this.params.ranges.forEach((key, index) => {
						if (index === 0) ranges.push(key[0])
						ranges.push(key[1])
					})
					this.params.ranges = ranges
				}
				this.GET_PriceStatistics()
			},

			GET_PriceStatistics() {
				this.params.start_time=parseInt(this.params.start_time)
				this.params.end_time=parseInt(this.params.end_time)
				API_Statistics.sellerMemberMoney(this.params).then(response => {
					this.priceListChart.setOption({
						tooltip: { trigger: 'axis' },
						grid:{
                            x: '50px',
                            y: '80px',
                            x2: '60px',
                            y2: '30px',
                        },
						legend: {
							show: true,
							data: [{ name: '营收总额' }, { name: '利润' }],
							top: '10px'
						},
						//color: ['#7CB5EC'],
						toolbox: {
							feature: {
								magicType: { type: ['line', 'bar'] },
								restore: {},
								saveAsImage: {}
							}
						},
						xAxis: {
							name: '日期',
							type: 'category',
							boundaryGap: false,
							data: response.xAxis
						},
						yAxis: {
							name: '金额（元）',
							type: 'value',
							axisLabel: {
								formatter: '{value} '
							}
						},
						series: [
							{
								type: "line",
								data: response.series[0].data,
								name: '营收总额',
								itemStyle: {
									normal: {
										lineStyle: {
											width: 2,
											color: "#1a43a9"
										},
										color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
												offset: 0,
												color: '#c2bbfe'
											},
											{
												offset: 1,
												color: '#d3edfb'
											}
										]),
									}
								},
								areaStyle: {
									normal: {}
								},
								symbolSize: 0,
								barWidth: 50,
								smooth: true
							},
							{
								type: "line",
								name: '利润',
								data: response.series[1].data,
								smooth: true,
								itemStyle: {
									normal: {
										lineStyle: {
											width: 2,
											color: '#4fa736'
										},
										color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
												offset: 0,
												color: '#acd9a9'
											},
											{
												offset: 1,
												color: '#d0eccd'
											}
										]),
									},
								},
								areaStyle: {
									normal: {}
								},
								symbolSize: 0,
								barWidth: 50,
								smooth: true
							}
						]
					})
				})
			},
			GET_MontPrice() {
				// console.log(params,"dddd")
				var str=this.value2.split("-")

				this.params2.year=Number(str[0]);
				this.params2.month=Number(str[1]);
				API_Statistics.getTurnoverMonthData(this.params2).then(response => {
					this.pageData.data = response;
				})
			}
		}
	}
</script>

<style scoped>
	.tipBox{background: #fff;margin-bottom: 10px;padding: 20px;border-radius: 5px;}
	.tipBox p{margin: 0;line-height: 18px;margin-bottom: 15px;font-size: 14px;}

	.conditions{display: inline-block;margin-right: 20px;}
	.conditions.right{float: right;}
	.conditions >>> .el-input{display: inline-block;}
	.conditions span{font-size: 14px;color: #606266;}

	.btnTab{margin-top: 20px;margin-bottom: -30px;position: relative;z-index: 1;width: 500px;}

</style>

